import axios from 'axios'
import { sessionLogout } from "@advise/styleguide";
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        sessionLogout().then(() => {
            console.info("Session closed");
        });
        return Promise.reject(error);
    }
);
axios.interceptors.request.use(
  function (config) {
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem(
      'token',
    )}|${sessionStorage.getItem(
      'ip_public',
    )}|${sessionStorage.getItem('ip_local')}`
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// const adviseApi = process.env.VUE_APP_ADVISE_API_LOCAL
const adviseApi = process.env.VUE_APP_ADVISE_API

export const getTickets = async (userId) => {
  try {
    const { data } = await axios.get(
      `${adviseApi}/ticket/ticket?user_id=${userId}`,
    )
    return data
  } catch (error) {
    return []
  }
}
