<template>
	<div style="height: 100%">
		<div>
			<table-ticket
				:isAdmin="isAdmin"
				:tickets="lstTickets"
				:isLoading="isLoading"
				:refreshData="refreshData"
				title="Ticket Status"
			/>
		</div>
	</div>
</template>
<script>
import { getTickets } from "./../services/service";
import { TableTicket } from "@advise/styleguide";

export default {
	components: {
		TableTicket
	},
	data() {
		return {
			isLoading: false,
			lstTickets: [],
			isAdmin: false,
			isScreensizeLess: false,
		};
	},
	methods: {
		async refreshData() {
			this.isLoading = true;
			const clientId = sessionStorage.getItem("client_id");
			this.lstTickets = await getTickets(clientId);
			this.isLoading = false;
		},
	},
	async created() {
		await this.refreshData();
	},
};
</script>
<style scoped></style>
